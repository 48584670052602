// import axios from "axios";
import TaskPanel from "./taskPanel.jsx";
import useEscape from "../services/useEscape.jsx";
import taskPanelButtonIMG from "../img/taskPanelButton.png";
import React, { useState, useEffect } from "react";
import { Reorder } from "framer-motion";

export default function TaskBar() {
  //  Local Storage
  const [task, setTask] = useState();
  const [todos, setTodos] = useState([]); //Can leave this empty
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  // Functions:

  // Load TODOs from local storage on app startup:
  useEffect(() => {
    const storedTodos = JSON.parse(localStorage.getItem("todos"));
    if (storedTodos !== null && [...storedTodos].length !== 0) {
      setTodos(storedTodos);
      setTask("");
    }
    console.log("Local Storage:", todos[1]);
  }, []);

  // Update local storage whenever TODOs change:
  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
  }, [todos]);

  // Add Task on keypress enter:
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (task.trim() !== "") {
        todos.push(task);
        console.log("Todos: ", todos);
        setTodos([...todos]);
        setTask("");
      }
      console.log("You pressed Enter: ", task);
      console.log(todos);
    }
  };

  const handleRemoveTodo = (index) => {
    const newTodos = todos.filter((_, i) => i !== index);
    setTodos(newTodos);
  };

  // Task Panel Toggle:
  function toggleTaskPanel() {
    console.log("Panel Triggered", isPanelOpen);
    setIsPanelOpen(!isPanelOpen);
  }

  // Task Panel Close (for Escape key usage given below.):
  function closeTaskPanel() {
    setIsPanelOpen(false);
    console.log("Close Panel Triggered");
  }
  // Esc to close the taskPanel
  useEscape(closeTaskPanel);

  // Render:
  return (
    <>
      <div className="pointer-events-none absolute top-1/2 left-1/2 h-32 w-72 -translate-x-1/2 -translate-y-1/2 transform">
        {/* Mini Tasks Display (Sides) */}
        <div className="pointer-events-auto">
          <div className="">
            {/* First Task List: */}
            <div className="invisible absolute left-72 -top-12 mx-12 w-64 md:visible 2xl:left-80">
              <h2 className="font-poppins text-xl font-bold">(ó﹏ò｡)</h2>
              <ul className="text-start">
                {todos.slice(0, 3).map((todo, index) => (
                  <li key={index}>
                    <button
                      className="px-3 font-poppins text-3xl font-thin"
                      onClick={() => handleRemoveTodo(index)}
                    >
                      o
                    </button>
                    {todo}
                  </li>
                ))}
              </ul>
            </div>

            {/* Second Task List: */}
            <div className="invisible absolute right-72 -bottom-20 mx-16 w-64 md:visible 2xl:right-80">
              <div className="flex flex-col items-end justify-end">
                <h2 className="font-poppins text-xl font-bold">৻( •̀ ᗜ •́ ৻)</h2>
                <ul className="text-end">
                  {todos.slice(3, 6).map((todo, index) => (
                    <li key={index}>
                      {todo}
                      <button
                        className="px-3 font-poppins text-3xl font-thin"
                        onClick={() => handleRemoveTodo(index + 3)}
                      >
                        o
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Actual taskBar */}
      <section className="absolute left-1/2 bottom-0 my-6 -translate-x-1/2 transform">
        {/*  */}
        {/* Show taskPanel Button */}
        <button
          onClick={toggleTaskPanel}
          className="absolute -top-4 left-1/2 h-auto -translate-x-1/2 cursor-pointer"
        >
          <img src={taskPanelButtonIMG} alt="" />
        </button>

        {/* Task Panel */}
        {isPanelOpen && (
          <TaskPanel
            todos={todos}
            setTodos={setTodos}
            handleRemoveTodo={handleRemoveTodo}
          />
        )}

        {/* Task Input Field */}
        <div class="w-screen">
          <div className="mx-9">
            <div
              className="mx-auto h-max min-w-[300px] rounded-full border-2 border-xblack bg-xbg 
            p-2 font-poppins text-xbrown md:w-1/2"
            >
              <div className="flex flex-row justify-between">
                {/* <div class="relative">
                  <input
                    type="text"
                    id="floating_outlined"
                    class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 
                    bg-transparent rounded-lg border-1 border-gray-300 appearance-none
                     dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
                     focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 
                    scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100
                    peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2
                    peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Floating outlined
                  </label>
                </div> */}
                <div className="m-1 min-w-max flex-grow">
                  <input
                    onKeyDown={handleKeyPress}
                    type="text"
                    maxLength={30}
                    value={task}
                    onChange={(e) => setTask(e.target.value)}
                    id="todos"
                    placeholder="ฅ^•ﻌ•^ฅ"
                    autocomplete="off"
                    className="text-md peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-center text-xblack  outline-none placeholder:text-xbrown placeholder:opacity-40 focus:ring-0"
                  />
                  {isPanelOpen ? null : (
                    <label
                      for="floating_outlined"
                      className="text-md pointer-events-none absolute top-1/2 left-1/2 z-10 origin-[0] -translate-x-1/2 
                       -translate-y-1/2 scale-100 transform rounded-3xl bg-xbg px-1 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:border-2 peer-focus:border-xblack peer-focus:px-6 peer-focus:py-2 peer-focus:text-xblack"
                    >
                      What are the tasks ?
                    </label>
                    // <label
                    //   for="floating_outlined"
                    //   className="absolute text-md duration-300 transform -translate-y-4
                    // scale-100 top-2 z-10 origin-[0] bg-xbg  px-2 peer-focus:px-2
                    // peer-focus:text-xblack peer-placeholder-shown:scale-100
                    // peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2
                    // peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:border-2 peer-focus:border-xblack rounded-3xl rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    // >
                    //   What are you working on ? ฅ^•ﻌ•^ฅ
                    // </label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
