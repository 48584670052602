import React from "react";
import "../customCSS/noScroll.css"; //Custom CSS that removes Scroll bar of the element
import CatCHDR from "../img/drawings/catCHDR.webp"; // Picture of cat in the taskPanel Nya ^~^
import { Reorder } from "framer-motion";

const TaskPanel = ({ todos, setTodos, handleRemoveTodo }) => {
  return (
    <div>
      <div
        className="z-0 mx-auto max-h-[750px] w-[75%] overflow-auto
        rounded-t-3xl border-x-2 border-t-4 border-xblack bg-xbg
         md:w-[50%] xl:w-[40%]"
      >
        <div className="m-9">
          <div className="flex gap-1 p-3 font-poppins text-3xl font-bold">
            <h2>Task List</h2>
            <h2 className="text-sm font-semibold">{todos.length}</h2>
          </div>
          <ul className="scroll-auto text-start font-poppins">
            <Reorder.Group values={todos} onReorder={setTodos}>
              {todos.map((todo, index) => (
                <Reorder.Item value={todo} key={todo}>
                  <li key={index}>
                    <div className="flex flex-row justify-between gap-3 py-1">
                      <div className="flex flex-row ">
                        <div
                          class="mx-3 h-5 w-5 rounded-full border-2 border-xblack"
                          onClick={() => handleRemoveTodo(index)}
                        ></div>
                        <div className="whitespace-nowrap px-3">{todo}</div>
                      </div>
                      {/* Line betweeen */}
                      <hr className="my-3 w-full border-xblack" />
                      {/* Index on the list */}
                      <div className="item-end flex justify-end">
                        {index + 1}
                      </div>
                    </div>
                  </li>
                </Reorder.Item>
              ))}
            </Reorder.Group>
            {/* A Block of empty space */}
            <div className="relative z-50 bg-xbg py-12">
              <img
                src={CatCHDR}
                className="pointer-events-none absolute right-0 w-3/5"
                alt="A cute cat"
              />
              {/* <p className="absolute bottom-0 left-0 font-semibold font-poppins"> (´｡• ω •｡`) 休憩してください...</p> */}
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TaskPanel;
