import "./App.css";
import MainPage from "./pages/mainPage.jsx";

function App() {
  return (
    <section className="overflow-x-hidden selection:text-xbrown">
      <MainPage />
    </section>
  );
}

export default App;
