export default function NavBar() {
  // Render:
  return (
    <section className="flex cursor-pointer flex-col">
      <div className="flex">
        <h1 className="font-quicksand text-2xl font-bold text-xbrown xl:text-3xl">
          Chaidoro
        </h1>
        <h1 className="text-md invisible absolute font-quicksand font-medium text-xblack md:visible md:relative">
          beta
        </h1>
      </div>
    </section>
  );
}
